import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import ResponsiveAppBar from "./Components/navbar/NavBar";
import AppRoutes from "./Components/routes/routes";
import Footer from "./Components/Footer/Footer";
function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "arfont",
    },
  });
  return (
    <div className="App sitefont min-h-full">
      <ThemeProvider theme={theme}>
        <ResponsiveAppBar />
        <AppRoutes />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
