import { createSlice } from "@reduxjs/toolkit";

export const LangSlice = createSlice({
  name: "lang",
  initialState: {
    value: "ar",
  },
  reducers: {
    changelang: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changelang } = LangSlice.actions;
export const selectLang = (state) => state.lang.value;
export default LangSlice.reducer;
