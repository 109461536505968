import React from "react";
import { motion } from "framer-motion";
import { selectLang } from "../redux/langslice";
import { useSelector } from "react-redux";
import Lang from "../Lang/Lang";
import Images from "../Components/ImageSlider/ImageSlider";

const Home = () => {
  const LangSelector = useSelector(selectLang);

  const HomeStrings = Lang(LangSelector).home;
  return (
    <div className=" items-center  justify-center content-center      text-center">
      <motion.div
        initial={{
          x: 200,
          scale: 0,
        }}
        animate={{
          x: 0,
          scale: 1,
        }}
        exit={{
          x: 200,
          scale: 0,
        }}
        id="head"
        className=" "
      >
        <h1 className="text-3xl mb-2">
          <span className="text-4xl text-amber-700">
            {HomeStrings.vartitle}
          </span>
          {HomeStrings.title}
        </h1>
        <Images />
      </motion.div>
      <motion.div
        initial={{
          x: 200,
          scale: 0,
        }}
        animate={{
          x: 0,
          scale: 1,
        }}
        exit={{
          x: 200,
          scale: 0,
        }}
        id="portfolio"
        className=" flex flex-col mt-52  justify-center content-center  px-6  "
      >
        <h1 className="text-3xl mb-2">
          <span className="text-5xl text-amber-700">
            {HomeStrings.vartitle}
          </span>
          {HomeStrings.title}
        </h1>
        <div className="w-full h-full mt-20 flex flex-col md:flex-row  justify-center  ">
          <p className="md:w-3/6 text-xl leading-loose  w-full md:self-start md:text-start mb-10 	">
            {HomeStrings.pragraph}
          </p>
          <div className="relative imagetommy imagetommylg md:mx-10 self-center rounded-xl overflow-hidden ">
            <img src={"/mallbg.jpg"} fill />
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{
          x: 200,
          scale: 0,
        }}
        animate={{
          x: 0,
          scale: 1,
        }}
        exit={{
          x: 200,
          scale: 0,
        }}
        id="portfolio"
        className=" flex flex-col mt-52  justify-center content-center  px-6 "
      >
        <h1 className="text-3xl mb-2">{HomeStrings.secondtitle}</h1>
        <div className="w-full h-full mt-20 flex flex-col md:flex-row  justify-center  ">
          <div className="relative imagetommy imagetommylg md:mx-10 self-center rounded-xl overflow-hidden ">
            <img src={"/phototraffic.jpg"} fill />
          </div>
          <p className="md:w-3/6 text-xl leading-loose  w-full md:self-start md:text-start mb-10 	">
            {HomeStrings.secondparagh}
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
