"use client";
import Lang from "../Lang/Lang";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";

import { selectLang } from "../redux/langslice";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";

const ContactUs = () => {
  const LangSelector = useSelector(selectLang);
  const contactUsStrings = Lang(LangSelector).contactus;

  return (
    <div className="fullview  ">
      <Paper
        elevation={0}
        className="grid grid-flow-row  md:grid-cols-2   items-center w-full h-full"
      >
        <Paper
          elevation={2}
          className="overflow-hidden   z-50 space-y-10 !text-white !bg-yellow-700  w-full h-full px-5 md:px-20 py-5 md:py-20  self-center place-self-center"
        >
          <h1 className=" text-3xl">{contactUsStrings.title} </h1>
          <a
            href="https://maps.app.goo.gl/pZ6sBXbmkuNPGBnP9"
            className="flex space-x-3 flex-row "
            target="_blank"
          >
            {" "}
            <FaLocationDot size={30} />
            <h2 className=" text-2xl">{contactUsStrings.adrees} </h2>
          </a>
          <a
            href="tel:01111454805"
            className="flex space-x-3 flex-row "
            target="_blank"
          >
            <FaPhone size={30} />
            <h2 className=" text-2xl">{contactUsStrings.phone1} </h2>
          </a>
          <a
            href="tel:01001709663"
            className="flex space-x-3 flex-row "
            target="_blank"
          >
            <FaPhone size={30} />
            <h2 className=" text-2xl">{contactUsStrings.phone2} </h2>
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=201001709663&text=%D9%85%D9%88%D9%84%20%D8%A7%D9%84%D8%B9%D8%B7%D8%A7%D8%B1&type=phone_number&app_absent=0"
            className="flex space-x-3 flex-row "
            target="_blank"
          >
            <FaWhatsapp size={30} />
            <h2 className=" text-2xl">{contactUsStrings.whatsapp} </h2>
          </a>
        </Paper>
        <Paper
          elevation={0}
          className="overflow-hidden w-full h-full self-center place-self-center"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1413.6114328621234!2d31.248843812742813!3d30.04621936736389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458410021b7befd%3A0xf3068670fd0f86af!2z2YXZiNmEINin2YTYudi32KfYsSAtIEFsLUF0dGFyIG1hbGw!5e0!3m2!1sen!2seg!4v1733158874613!5m2!1sen!2seg"
            width="100%"
            height="100%"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Paper>
      </Paper>
    </div>
  );
};
export default ContactUs;
