"use client";
import { changelang, selectLang } from "../redux/langslice";
import Lang from "../Lang/Lang";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaWhatsapp } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdMessage } from "react-icons/md";
import { GrLanguage } from "react-icons/gr";

import { FaFacebook } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { FaInstagram } from "react-icons/fa6";

const Tommy = () => {
  const LangSelector = useSelector(selectLang);
  const DynamicStrings = Lang(LangSelector).dynamic;
  const navStrings = Lang(LangSelector).navbar;
  const dispatch = useDispatch();

  const Card = ({ children }) => {
    return (
      <div className="bg-white p-5 m-2 h-fit rounded-xl shadow-lg">
        {children}
      </div>
    );
  };

  return (
    <div className="md:hidden min-h-screen">
      <div
        onClick={() => {
          if (LangSelector === "ar") {
            dispatch(changelang("en"));
          } else {
            dispatch(changelang("ar"));
          }
        }}
        className="w-full flex gap-2 cursor-pointer select-none  text-center items-center h-10 px-5"
      >
        <GrLanguage className="text-blue-600" />
        {LangSelector === "ar" ? (
          <h1 className="text-blue-600">En</h1>
        ) : (
          <h1 className="text-blue-600">ع</h1>
        )}
      </div>
      <Card>
        <div className="w-full flex flex-col gap-5 justify-center">
          <div className="w-20 h-20 relative place-self-center rounded-lg overflow-hidden  ">
            <img fill={true} alt="tommy" src={"/Tommy.JPG"} />
          </div>
          <div className="w-full text-center">
            <h1 className="text-blue-700 text-xl">{DynamicStrings.name}</h1>
            <h3 className=" text-base">{DynamicStrings.title}</h3>
          </div>
          <div className="w-full grid grid-flow-col justify-center gap-8 px-20">
            <a target="_blank" href={"tel:01065024373"}>
              <div className="w-10 h-10 bg-blue-600 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <FaPhone size={25} fill="white" />
              </div>
            </a>
            <a
              target="_blank"
              href={
                "https://api.whatsapp.com/send/?phone=201065024373&text&type=phone_number&app_absent=0"
              }
            >
              <div className="w-10 h-10 bg-green-200 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <FaWhatsapp size={25} fill="white" />
              </div>
            </a>
            <a target="_blank" href={"sms:01065024373"}>
              <div className="w-10 h-10 bg-blue-600 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <MdMessage size={25} fill="white" />
              </div>
            </a>
          </div>
        </div>
      </Card>
      <Card>
        <div className="w-full flex flex-col gap-5 justify-center">
          <div className="w-full grid grid-cols-3 items-center content-center place-content-center place-items-center justify-center gap-4 px-5">
            <a
              target="_blank"
              href={
                "https://api.whatsapp.com/send/?phone=201065024373&text&type=phone_number&app_absent=0"
              }
            >
              <div className="w-10 h-10 bg-green-200 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <FaWhatsapp size={25} fill="white" />
              </div>
            </a>

            <a
              target="_blank"
              href={
                "https://www.instagram.com/hatem_elsayad?igsh=Mnd3a2V3OTM1Y2lp&utm_source=qr"
              }
            >
              <div className="w-10 h-10 bg-purple-200 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <FaInstagram size={25} fill="white" />
              </div>
            </a>
            <a
              target="_blank"
              href={
                "https://www.facebook.com/share/15f7tnVLNN/?mibextid=LQQJ4d"
              }
            >
              <div className="w-10 h-10 bg-blue-600 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <FaFacebook size={25} fill="white" />
              </div>
            </a>
            <a target="_blank" href={"mailto:hatemmondee@gmail.com"}>
              <div className="w-10 h-10 bg-red-500 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <BiLogoGmail size={25} fill="white" />
              </div>
            </a>
            <a
              target="_blank"
              href={
                "https://www.linkedin.com/in/hatem-elsayad-55977116b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              }
            >
              <div className="w-10 h-10 bg-blue-400 flex justify-center items-center select-none cursor-pointer rounded-lg text-lg text-center">
                <FaLinkedinIn size={25} fill="white" />
              </div>
            </a>
          </div>
        </div>
      </Card>
      <center className="mt-5">
        <a
          className=""
          href="data:text/vcard;charset=utf-8,BEGIN:VCARD%0AVERSION:3.0%0AFN:Hatem%20Elsayad%0ATEL:01065024373%0AEMAIL:hatemmondee@gmail.com%0AEND:VCARD"
          download="ContactName.vcf"
          target="_blank"
        >
          Add To Contact
        </a>
      </center>
    </div>
  );
};

export default Tommy;
