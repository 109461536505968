const Languages = {
  en: {
    home: {
      vartitle: "Al-Attar Mall ",
      title: ` is Your Best choice`,

      pragraph: `Thank God, Al Attar Mall has been prepared
      The mall specializes in mobile services (mobile phones, accessories, spare parts, etc.)
      The mall has four floors, a garage, and a large cafeteria on the mall’s area, 1080 net square meters. 3 floors, ground, first, and second. Commercial shops. The inventory of each floor consists of 35 stores of different sizes, 52 stores of different sizes, and a garage with an area of ​​\u200b\u200bthe mall. It will serve the mall only.
      It has 2 elevators and very wide corridors
      The mall is fully finished with Az Securit and a suspended ceiling
      LED lighting for stores and corridors, the air conditioning pipes are installed, and the air conditioning drain in the mall is fully installed. Fire extinguishers and extinguisher hoses
      Fire tanks, a security company, a kitchen cleaning company in every floor, including women. You want a very respectable face, cladding and securitization. It means something. God willing, I will suggest a follow-up in your presence.
      *There will be discounts on rent, God willing, to encourage people to enlighten us
      We are in the final stages of installing security, meter and fire pipes
      `,
      secondtitle: "Why do I choose Al Attar Mall?",
      secondparagh: `As a shop owner in any market, you likely face two significant challenges. The first is that the world is extremely crowded, making it difficult for customers to reach you. For example, if I drive to your store to purchase goods, I might struggle to find a parking spot. Often, the nearest available parking is far from your store.

The second challenge is that customers themselves may feel overwhelmed by the intense crowding and heat. For them, visiting your store can feel like a burden.

At Al-Attar Mall, we solve these problems for you. We offer a spacious garage where your customers can easily park and take care of their business without hassle. Additionally, because we prioritize customer comfort, we've created a large cafeteria on the mall's rooftop, providing a relaxing space for your customers to enjoy. We ensure your customers' comfort every step of the way.`,
    },
    map: {
      firstfloor: "First Floor",
      secondfloor: "Second Floor",
      thirdfloor: "Third Floor",
      iffilenotexist:
        "If this File does not open for you you can just Download it",
      download: "Download Map File",
    },
    contactus: {
      title: "Al-Attar Mall Contact Center",
      adrees:
        "19 Hassan Al-Shujaa Street, corner of Ard Sharif, meters away from Abdul Aziz Abdeen Street",
      phone1: "01111454805",
      phone2: "01001709663",
      whatsapp: "01001709663",
    },
    navbar: {
      home: {
        name: "Home",
        route: "/",
      },
      about: {
        name: "Map",
        route: "/map",
      },
      contact: {
        name: "Contact US",
        route: "/contact",
      },
      langopt: "Language",
      Profile: "Profile",
      Account: "Account",
      Logout: "Logout",
      login: "Login",
    },
    loginpage: {
      login: "Loign",
      titlelogin: "Al-Attar Mall is the best choice for you",
      signup: "SignUp",
      username: "User name",
      password: "Passowrd",
      btnlogin: "Login Now",
      btnsignup: "SignUp",
    },
    signuppage: {
      login: "Loign",
      titlelogin: "Al-Attar Mall is the best choice for you",
      signup: "SignUp",
      username: "User name",
      password: "Passowrd",
      btnlogin: "Login Now",
      btnsignup: "SignUp",
      name: "name",
      phonenumber: "Phone Number",
      retypepassword: "Retype Password",
    },
    dynamic: {
      name: "Eng Hatem Elsayad",
      title: "Software Engineer",
    },
  },
  ar: {
    home: {
      vartitle: "مول العطار",
      title: ` هو إختيارك الافضل`,
      pragraph: `تم بحمد الله تجهيز مول العطار 
المول متخصص لخدمات الموبايل (موبايلات ، إكسسوارات ، قطع غيرات ، الى اخره)
الموول  عدد أربع أدور  وجراج وكافيتيريا  كبيرة علي مساحه المول مساحه ١٠٨٠ متر صافي ٣ ادوار ارضي واول وتاني محلات تجارية تجارية مخزون كل دور عباره عن ٣٥ محل مساحات مختلفةه و٥٢ مخزن مقاسات مختلفةه وجراج بمساحه مول هيخدم علي الموول فقط الموول
 موجود فيه ٢ اسانسير ممرات واسعة جدا
المول مشطب بالكامل ازاز سيكوريت سقف معلق
اضاءه ليدات للمحلات والممرات و موازير التكييف راكبه والصرف بتاع التكيف المول سيفتي بالكامل طافيات الحريق وخراطيم مطافي
خزانات للحريق شركه امن شركه نظافه المطابخ في كل دور منها حريمي تريدي وجهه محترمه جدا كلادينج وسيكوريت يعني حاجه ان شاء الله علي يقترح يتبعق بحضرتكم
*سيكون  هناك  خصومات في الايجار أن شاء الله علشان نشجع الناس تنورنا
 احنا في اخر مراحل تركيب السيكوريت وتركيب العداد ومواسير الحريق
      `,
      secondtitle: "ليه أختار مول العطار ",
      secondparagh: `لو انت كنت صاحب محل في اي سوق فا انت بتعاني من حاجتين اول حاجة ان الدنيا زحمة جداً جداً فا بالتالي الوصول ليك بقى صعب مثال لو انا داخل عندك بعربيتي عشان اخد بضاعة مش لاقي مكان اركن فيه .. فا بالتالي هدور على اقرب مكان اركن فيه واللي بالمناسبة هيكون بعيد عنك جداً .طيب الحاجة  التانية ؟ 
الحاجة التانية ان العميل نفسة مبيعرفش ينظر حاجته فين من كتر الزحمة والحر فا بيبقى اليوم اللي جايلك فيه ده يوم ازمة بالنسباله . 

طيب احنا بقى حلينالك المشاكل دي كلها في مول العطار لان عندنا جراج كبير لعملائك عشان يتنظروا شغلهم فيه براحتهم ومش كدا وبس  عشان احنا بنقدر راحة العملاء عملناك  كافيتيريا كبيرة جداً في الروف بتاع المول يعني موفرين كل حاجة لراحة عميلك .`,
    },
    map: {
      firstfloor: "الدور الاول",
      secondfloor: "الدور الثاني",
      thirdfloor: "الدور الثالث",
      iffilenotexist: "لو الملف مش ظاهر عندك تقدر تنزله من هنا",
      download: "تنزيل ملف الخريطة",
    },
    contactus: {
      title: "تواصل معنا مول العطار",
      adrees:
        "١٩ شارع حسن الشجاع ناصية ارض شريف علي بعد أمتار من شارع عبد العزيز عابدين",
      phone1: "01111454805",
      phone2: "01001709663",
      whatsapp: "01001709663",
    },
    navbar: {
      home: {
        name: "الرئيسية",
        route: "/",
      },
      about: {
        name: "الخريطة ",
        route: "/map",
      },
      contact: {
        name: "اتصل بنا",
        route: "/contact",
      },

      langopt: "اللغة",
      Profile: "الملف الشخصي",
      Account: "الحساب",
      Logout: "تسجيل خروج",
      login: "تسجيل دخول",
    },
    loginpage: {
      login: "تسجيل الدخول",
      titlelogin: "مول العطار هو افضل اختياراتك",

      signup: "إنشاء حساب",
      username: "إسم المستخدم إنجليزي",
      password: "الباسورد",
      btnlogin: "تسجيل دخول",
      btnsignup: "إنشاء حساب",
    },

    signuppage: {
      login: "تسجيل الدخول",
      titlelogin: "مول العطار هو افضل اختياراتك",
      signup: "إنشاء حساب",
      username: "إسم المستخدم إنجليزي",
      password: "الباسورد",
      btnlogin: "تسجيل دخول",
      btnsignup: "إنشاء حساب",
      name: "الإسم",
      phonenumber: "رقم الهاتف",
      retypepassword: "تأكيد كلمة السر",
    },
    dynamic: {
      name: "مهندس حاتم الصياد",
      title: "مهندس سوفت وير",
    },
  },
};

const Lang = (curlang) => {
  switch (curlang) {
    case "ar":
      return Languages.ar;
      break;
    case "en":
      return Languages.en;
      break;
  }
};
export default Lang;
