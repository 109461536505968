"use client";
import { Paper } from "@mui/material";

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { selectLang } from "../redux/langslice";
import Lang from "../Lang/Lang";
import { useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const LangSelector = useSelector(selectLang);
  const MapStrings = Lang(LangSelector).map;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={MapStrings.firstfloor} {...a11yProps(0)} />
          <Tab label={MapStrings.secondfloor} {...a11yProps(1)} />
          <Tab label={MapStrings.thirdfloor} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel
        className="h-screen place-self-center self-center "
        value={value}
        index={0}
      >
        <Paper
          variant="elevation"
          elevation={20}
          className=" flex rounded-lg overflow-hidden   place-self-center"
          style={{ height: "75vh", width: "80vw", placeSelf: "center" }}
          square={false}
        >
          <object
            data="/1.pdf"
            type="application/pdf"
            className="w-full h-full  text-center flex flex-col  justify-center "
          >
            <h1> لو الملف مش ظاهر عندك تقدر تنزله من هنا </h1>
            <a
              href="/units.pdf"
              download={true}
              className="select-none text-orange-800  "
            >
              تنزيل ملف الخريطة{" "}
            </a>
          </object>
        </Paper>
      </CustomTabPanel>
      <CustomTabPanel
        className="h-screen place-self-center self-center "
        value={value}
        index={1}
      >
        <Paper
          variant="elevation"
          elevation={20}
          className=" flex rounded-lg overflow-hidden   place-self-center"
          style={{ height: "75vh", width: "80vw", placeSelf: "center" }}
          square={false}
        >
          <object
            data="/2.pdf"
            type="application/pdf"
            className="w-full h-full  text-center flex flex-col  justify-center "
          >
            <h1> {MapStrings.iffilenotexist}</h1>
            <a
              href="/units.pdf"
              download={true}
              className="select-none text-orange-800  "
            >
              {MapStrings.download}
            </a>
          </object>
        </Paper>
      </CustomTabPanel>
      <CustomTabPanel
        className="h-screen place-self-center self-center "
        value={value}
        index={2}
      >
        <Paper
          variant="elevation"
          elevation={20}
          className=" flex rounded-lg overflow-hidden   place-self-center"
          style={{ height: "75vh", width: "80vw", placeSelf: "center" }}
          square={false}
        >
          <object
            data="/3.pdf"
            type="application/pdf"
            className="w-full h-full  text-center flex flex-col  justify-center "
          >
            <h1> لو الملف مش ظاهر عندك تقدر تنزله من هنا </h1>
            <a
              href="/units.pdf"
              download={true}
              className="select-none text-orange-800  "
            >
              تنزيل ملف الخريطة{" "}
            </a>
          </object>
        </Paper>
      </CustomTabPanel>
    </Box>
  );
}

const Map = () => {
  return (
    <div className="fullview flex justify-center relative ">
      <BasicTabs />
    </div>
  );
};
export default Map;
