import * as React from "react";

import SimpleImageSlider from "react-simple-image-slider";

const Images = () => {
  const [scale, setScale] = React.useState({
    width: null,
    height: null,
  });
  const [curimage, setCurImage] = React.useState(0);
  React.useEffect(() => {
    setScale({
      ...scale,
      width: window.innerWidth,
      height: window.innerHeight * 0.75,
    });
  }, []);

  const srcs = [
    { url: "/mallimages/_1.jpg" },
    { url: "/mallimages/_2.jpg" },
    { url: "/mallimages/_3.jpg" },
    { url: "/mallimages/_4.jpg" },
    { url: "/mallimages/_5.jpg" },
    { url: "/mallimages/_6.jpg" },
    { url: "/mallimages/_7.jpg" },
    { url: "/mallimages/_8.jpg" },
    { url: "/mallimages/_9.jpg" },
    { url: "/mallimages/_10.jpg" },
    { url: "/mallimages/_11.jpg" },
    { url: "/mallimages/_12.jpg" },
    { url: "/mallimages/_13.jpg" },
    { url: "/mallimages/_14.jpg" },
    { url: "/mallimages/_15.jpg" },
    { url: "/mallimages/_16.jpg" },
    { url: "/mallimages/_17.jpg" },
    { url: "/mallimages/_18.jpg" },
    { url: "/mallimages/_19.jpg" },
    { url: "/mallimages/_20.jpg" },
    { url: "/mallimages/_21.jpg" },
    { url: "/mallimages/_22.jpg" },
    { url: "/mallimages/_23.jpg" },
    { url: "/mallimages/_24.jpg" },
    { url: "/mallimages/_25.jpg" },
    { url: "/mallimages/_26.jpg" },
    { url: "/mallimages/_27.jpg" },
    { url: "/mallimages/_28.jpg" },
    { url: "/mallimages/_29.jpg" },
    { url: "/mallimages/_30.jpg" },
    { url: "/mallimages/_31.jpg" },
    { url: "/mallimages/_32.jpg" },
    { url: "/mallimages/_33.jpg" },
    { url: "/mallimages/_34.jpg" },
  ];

  React.useEffect(() => {}, [curimage]);
  if (scale.height !== null || scale.width !== null) {
    return (
      <div>
        <SimpleImageSlider
          width={scale.width}
          height={scale.width < 600 ? scale.height * 0.8 : scale.height}
          images={srcs}
          showBullets={true}
          showNavs={true}
          autoPlay={true}
          navStyle={"3"}
        />
      </div>
    );
  }
};

export default Images;
