import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { ButtonBase, FormControl, InputLabel, Select } from "@mui/material";
import { changelang, selectLang } from "../../redux/langslice";
import { useDispatch, useSelector } from "react-redux";
import Lang from "../../Lang/Lang";
import { ReactComponent as Logo } from "../../assets/MainLogo.svg";

import routes from "../routes/routes";
import { useNavigate } from "react-router-dom";
function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [visible, setVisible] = React.useState(true);
  const [signedIn, setSignedIn] = React.useState(false);
  const LangSelector = useSelector(selectLang);
  const navStrings = Lang(LangSelector).navbar;
  const pages = [navStrings.home, navStrings.about, navStrings.contact];
  const settings = [navStrings.Profile, navStrings.Account, navStrings.Logout];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blockNavbar = ["login", "signup", "dynamic"];
  const [rtl, setRtl] = React.useState(false);
  const [dir, setDir] = React.useState("rtl");
  React.useEffect(() => {
    if (rtl) {
      setDir("rtl");
    } else {
      setDir("ltr");
    }
  }, [rtl]);
  React.useEffect(() => {
    let htmltag = document.getElementsByTagName("HTML");
    htmltag[0].dir = dir;
  }, [dir]);
  React.useEffect(() => {
    switch (LangSelector) {
      case "ar":
        setRtl(true);
        break;

      case "en":
        setRtl(false);

        break;
    }
  }, [LangSelector]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let pathnames = window.location.pathname;
  React.useEffect(() => {
    blockNavbar.map((item) => {
      if (pathnames.includes(item)) {
        setVisible(false);
      }
    });
  }, []);

  if (visible && !pathnames.includes("dyncmic/tommy")) {
    return (
      <AppBar
        sx={{
          maxWidth: "100%",
          backgroundColor:
            "rgb(249 168 37 / var(--tw-bg-opacity))" /* #f9a825 */,
        }}
        className="bg-yellow-800 md:px-20 "
        position="sticky"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              className="cursor-pointer"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "GrayText",
                textDecoration: "none",
              }}
            >
              <Logo className="w-20" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem key={index}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo className="w-20 " />
            </Typography>
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              className="mx-20 "
            >
              {pages.map((page, index) => (
                <Button
                  key={index}
                  sx={{ my: 2, color: "black", fontSize: 17, display: "block" }}
                  onClick={() => {
                    navigate(page.route);
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
            <Box
              maxHeight={{}}
              sx={{ maxHeight: 20, maxWidth: 90 }}
              className="mx-10"
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {navStrings.langopt}
                </InputLabel>
                <Select
                  sx={{ maxHeight: 30 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={navStrings.langopt}
                  value={LangSelector}
                  onChange={(e) => {
                    dispatch(changelang(e.target.value));
                  }}
                >
                  <MenuItem value={"ar"}> العربية</MenuItem>
                  <MenuItem value={"en"}>English</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  } else {
    return null;
  }
}
export default ResponsiveAppBar;
