import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../../Pages/Home";
import Map from "../../Pages/Map";
import ContactUs from "../../Pages/ContactUS";
import Tommy from "../../Pages/DynamicTommy";

const AppRoutes = () => {
  const routes = [
    {
      path: "/",
      name: "Home",
      element: <Home />,
    },
    {
      path: "/map",
      name: "Map",
      element: <Map />,
    },
    {
      path: "/contact",
      name: "Contact",
      element: <ContactUs />,
    },
    {
      path: "/dynamic/tommy",
      name: "dynamic_Tommy",
      element: <Tommy />,
    },
  ];

  return (
    <>
      <Routes>
        {routes.map((item, index) => {
          return <Route path={item.path} element={item.element} />;
        })}
      </Routes>
    </>
  );
};

export default AppRoutes;
